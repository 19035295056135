<template>
  <v-app-bar app color="#333" dark>
    <div class="d-flex align-center">
      <v-img
        alt="Adriano Logo"
        class="shrink mr-2"
        contain
        src="../assets/adriano.png"
        transition="scale-transition"
        width="128"
      />
    </div>

    <v-spacer></v-spacer>

    <Settings />
  </v-app-bar>
</template>

<script>
import Settings from './Settings';

export default {
  name: 'Navbar',

  components: {
    Settings,
  },
};
</script>
