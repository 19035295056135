<template>
  <v-app>
    <Navbar />
    <v-main>
      <Adicionar />
      <Medidas />
      <Calcular />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar';
import Adicionar from './components/Adicionar';
import Medidas from './components/Medidas';
import Calcular from './components/Calcular';

export default {
  name: 'App',

  components: {
    Navbar,
    Adicionar,
    Medidas,
    Calcular,
  },

  mounted() {
    if (localStorage.capacity) {
      this.$store.state.capacity = Number(localStorage.capacity);
    } else {
      this.$store.state.capacity = 6500;
    }
    if (localStorage.disco) {
      this.$store.state.disco = Number(localStorage.disco);
    } else {
      this.$store.state.disco = 5;
    }
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}
</style>
