<template>
  <v-container class="my-5">
    <v-card
      flat
      class="mx-3 my-5 item"
      v-for="(medida, index) in this.$store.state.medidas"
      :key="medida.index"
    >
      <v-layout row wrap class="pa-3">
        <v-flex xs6 class="px-3">
          <div class="caption grey--text">Medida</div>
          <div>{{ medida.medida }}</div>
        </v-flex>
        <v-flex xs4>
          <div class="text-center">
            <div class="caption grey--text">Qtd.</div>
            <div>{{ medida.qtd }}</div>
          </div>
        </v-flex>
        <v-flex xs2>
          <div class="mt-4 text-right">
            <v-icon size="20" color="red" @click="remove(index)">delete</v-icon>
          </div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Medidas',
  methods: {
    remove(index) {
      this.$store.state.medidas.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.item {
  border-left: 4px solid #ffd204;
}
</style>
