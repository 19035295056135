<template>
  <v-row justify="center" class="mt-5">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="grey darken-3"
          dark
          elevation="1"
          class="adicionar my-1"
          >Adicionar</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <h3 class="px-5">Adicionar medida</h3>
        </v-card-title>
        <v-card-text>
          <v-form class="px-3" ref="form">
            <v-text-field
              label="Medida"
              v-model="medida"
              type="number"
              :rules="inputRules"
              filled
            ></v-text-field>
            <v-row class="justify-center mb-2">
              <h3 class="blue--text">Quantidade</h3>
            </v-row>
            <v-row class="my-1 px-3 justify-center">
              <v-btn
                small
                @click="subtrair"
                color="grey darken-2"
                dark
                class="headline"
                >&minus;</v-btn
              >
              <h3 class="mt-1 mx-4 blue--text">{{ qtd }}</h3>
              <v-btn
                small
                @click="somar"
                color="grey darken-2"
                dark
                class="headline"
                >&plus;</v-btn
              >
            </v-row>
            <v-row class="mt-5 pa-5 justify-space-between">
              <v-btn text class="error" small @click="cancelar()"
                >Cancelar</v-btn
              >
              <v-btn text class="success" small @click="submit()"
                >Adicionar</v-btn
              >
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      medida: '',
      qtd: 1,
      inputRules: [
        (v) => v.length >= 1 || 'Insira a medida',
        (v) =>
          Number(v) < localStorage.capacity ||
          Number(v) < 6500 ||
          'Número inválido',
      ],
    };
  },
  methods: {
    somar() {
      this.qtd++;
    },
    subtrair() {
      if (this.qtd > 1) this.qtd--;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.state.medidas.push({ medida: this.medida, qtd: this.qtd });
        this.dialog = false;
        this.qtd = 1;
      }
    },
    cancelar() {
      this.dialog = false;
      this.qtd = 1;
    },
  },
};
</script>

<style>
.adicionar.v-btn:focus::before {
  opacity: 0 !important;
}
</style>
