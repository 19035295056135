import jsPDF from 'jspdf';
import logo from '../assets/logos/logo2';

export default (resultados) => {
  // Cria PDF
  let doc = new jsPDF();
  doc.setFont('helvetica');
  // Logotipo
  doc.addImage(logo, 'PNG', 150, 15, 45, 15);
  // Titulo
  doc.setFontSize(12);
  doc.text(15, 15, 'Optimização de barras', {
    baseline: 'top',
  });
  doc.setFont('helvetica', 'bold');
  doc.text(15, 24.7, `Listagem de cortes`, {
    baseline: 'bottom',
  });

  // Tabela
  let tabela = { l: 17, h: 40 };
  let maxAltura = 0;
  for (let i = 0; i < resultados.length; i++) {
    if (tabela.h > 260) {
      doc.addPage();
      tabela.h = 40;
    }
    doc.setFont('helvetica', 'normal');
    doc.setTextColor('#673ab7');
    doc.text(`Barra ${i + 1}:`, tabela.l, tabela.h);
    doc.setTextColor('#000000');
    doc.text(`${resultados[i].maxValue}`, tabela.l + 20, tabela.h);
    doc.setTextColor('#2196F3');
    doc.text(`Medidas:`, tabela.l, tabela.h + 8);
    doc.setTextColor('#000000');
    // Medidas
    let altura = tabela.h + 15;
    for (let j = 0; j < resultados[i].medidas.length; j++) {
      doc.text(`- ${resultados[i].medidas[j]}`, tabela.l + 3, altura);
      altura += 5;
    }
    altura = altura - tabela.h - 3;

    maxAltura = altura > maxAltura ? altura : maxAltura;
    tabela.l += 36;
    if (tabela.l > 195) {
      let coluna = 15;
      for (let j = 0; j < 5; j++) {
        // Tabela
        doc.setLineWidth(0.4);
        doc.rect(coluna, tabela.h - 5, 36, 7);
        doc.rect(coluna, tabela.h + 2, 36, maxAltura);
        coluna += 36;
      }
      tabela.l = 17;
      tabela.h += maxAltura + 7;
      maxAltura = 0;
    }
  }
  let coluna = 15;
  for (let j = 0; j < resultados.length % 5; j++) {
    // Tabela
    doc.setLineWidth(0.4);
    doc.rect(coluna, tabela.h - 5, 36, 7);
    doc.rect(coluna, tabela.h + 2, 36, maxAltura);
    coluna += 36;
  }

  // Salva PDF
  doc.save(`listagem.pdf`);
};
