<template>
  <v-row justify="center" class="my-3">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#ffd204"
          v-bind="attrs"
          v-on="on"
          @click="calcular"
          class="mb-1"
          elevation="1"
        >
          Calcular
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="title px-4">
          Optimização:
        </v-card-title>
        <div v-for="(resultado, index) in resultados" :key="index" class="py-1">
          <v-card-text class="py-1 body-1">
            <span class="deep-purple--text">Barra nº {{ index + 1 }}:</span>
            {{ resultado.maxValue }}
          </v-card-text>
          <v-card-text class="py-1 body-1 blue--text">
            Medidas:
          </v-card-text>
          <ul class="mx-3">
            <li v-for="(item, i) in resultado.medidas" :key="i">{{ item }}</li>
          </ul>
          <v-divider class="mt-3"></v-divider>
        </div>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            class="imprimir"
            @click.prevent="imprimir"
          >
            Imprimir
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="fechar">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import print from '../print/print';
export default {
  data() {
    return {
      dialog: false,
      resultados: [],
    };
  },
  computed: {
    medidas: function() {
      let arr = [];
      this.$store.state.medidas.forEach((element) => {
        for (let i = 0; i < element.qtd; i++)
          arr.push({ w: Number(element.medida), v: Number(element.medida) });
      });
      return arr;
    },
  },
  methods: {
    fechar() {
      this.resultados = [];
      this.dialog = false;
    },
    imprimir() {
      print(this.resultados);
    },
    knapsack(items, capacity, disco) {
      var memo = [];
      for (var i = 0; i < items.length; i++) {
        var row = [];
        for (var cap = 1; cap <= capacity; cap++) row.push(getSolution(i, cap));
        memo.push(row);
      }

      return getLast();

      function getLast() {
        var lastRow = memo[memo.length - 1];
        let ss = lastRow[lastRow.length - 1].subset;
        ss.forEach((el) => {
          const index = items.indexOf(el);
          if (index > -1) {
            items.splice(index, 1);
          }
        });
        return lastRow[lastRow.length - 1];
      }

      function getSolution(row, cap) {
        const NO_SOLUTION = { maxValue: 0, subset: [] };
        // the column number starts from zero.
        var col = cap - 1;
        var lastItem = items[row];
        // The remaining capacity for the sub-problem to solve.
        var remaining = cap - (lastItem.w + disco);

        // Refer to the last solution for this capacity,
        // which is in the cell of the previous row with the same column
        var lastSolution =
          row > 0 ? memo[row - 1][col] || NO_SOLUTION : NO_SOLUTION;
        // Refer to the last solution for the remaining capacity,
        // which is in the cell of the previous row with the corresponding column
        var lastSubSolution =
          row > 0 ? memo[row - 1][remaining - 1] || NO_SOLUTION : NO_SOLUTION;

        // If any one of the items weights greater than the 'cap', return the last solution
        if (remaining < 0) {
          return lastSolution;
        }

        // Compare the current best solution for the sub-problem with a specific capacity
        // to a new solution trial with the lastItem(new item) added
        var lastValue = lastSolution.maxValue;
        var lastSubValue = lastSubSolution.maxValue;

        var newValue = lastSubValue + (lastItem.v + disco);
        if (newValue >= lastValue) {
          // copy the subset of the last sub-problem solution
          var _lastSubSet = lastSubSolution.subset.slice();
          _lastSubSet.push(lastItem);
          return { maxValue: newValue, subset: _lastSubSet };
        } else {
          return lastSolution;
        }
      }
    },
    calcular() {
      let items = [...this.medidas];
      while (items.length > 0) {
        let res = this.knapsack(
          items,
          this.$store.state.capacity,
          this.$store.state.disco
        );
        // console.log('Barra: ', res.maxValue);
        // console.log('Medidas: ');

        let aux = { maxValue: res.maxValue, medidas: [] };

        for (let index = 0; index < res.subset.length; index++) {
          // console.log(res.subset[index].w);
          aux.medidas.push(res.subset[index].w);
        }
        this.resultados.push(aux);
      }
    },
  },
};
</script>

<style>
.v-btn:focus::before {
  opacity: 0 !important;
}
</style>
