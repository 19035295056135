<template>
  <v-row justify="end">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" fab dark small color="#333" depressed class="settings"
          ><v-icon>settings</v-icon></v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <h3 class="px-5">Mudar configurações</h3>
        </v-card-title>
        <v-card-text>
          <v-form class="px-2" ref="form">
            <v-container>
              <v-text-field
                label="Tamanho da barra"
                v-model="capacity"
                value="disco"
                type="number"
                filled
              ></v-text-field>
              <v-text-field
                label="Espessura do disco"
                v-model="disco"
                type="number"
                filled
              ></v-text-field>

              <v-btn text class="success mx-0 mt-3" @click="submit()"
                >Guardar</v-btn
              >
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      capacity: 0,
      disco: 0,
      inputRules: [(v) => v.length >= 1 || 'Insira um número'],
    };
  },
  mounted() {
    if (localStorage.capacity) {
      this.capacity = Number(localStorage.capacity);
    } else {
      this.capacity = 6500;
    }
    if (localStorage.disco) {
      this.disco = Number(localStorage.disco);
    } else {
      this.disco = 5;
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        localStorage.capacity = this.capacity;
        this.$store.state.capacity = Number(this.capacity);
        localStorage.disco = this.disco;
        this.$store.state.disco = Number(this.disco);
        this.dialog = false;
      }
    },
  },
};
</script>

<style>
.settings.v-btn:focus::before {
  opacity: 0 !important;
}
</style>
