import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    medidas: [],
    capacity: 0,
    disco: 0,
  },
  mutations: {
    updateMedidas(state, payload) {
      state.medidas = payload;
    },
  },
  actions: {},
  modules: {},
});
